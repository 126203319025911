<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.sms_gate') }}</h1>
        <v-tabs :dark="false"
                :icons-and-text="false"
                :grow="false" >
            <v-tab v-for="(tab, index) in tabs" :key="index">
                <v-icon top>
                    {{ tab.icon }}
                </v-icon>
                {{ tab.title }}
            </v-tab>
            <v-tab-item>
                <v-row>
                    <v-col cols="12" md="6">
                        <RadioGroup v-model="enableSmsSending"
                                    class="mt-1"
                                    :options="defaultOptions"
                        >
                        </RadioGroup>
                        <template v-if="enableSmsSending">
                            <TextField v-for="(item, index) in filteredSynchSetupEntries" :key="index"
                                       :label="$t(`sms_gate.${item.attributes.name}`)"
                                       @change="updateList(item.id)"
                                       v-model="item.attributes.value"
                            ></TextField>
                        <v-row>
                            <v-col cols="12" md="10">
                                <TextField v-model="phone"
                                           name="phone"
                                           ref="phone"
                                           rules="phone"
                                           :label="$t('labels.phone_to_test')"></TextField>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn color="secondary"
                                       :loading="loader"
                                       :disabled="!validPhone"
                                >{{ $t('buttons.send') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-btn color="secondary"
                               @click="save()"
                               :loading="loader"
                               :disabled="loader"
                        >{{ $t('buttons.save') }}</v-btn>
                        </template>

                    </v-col>
                </v-row>

            </v-tab-item>
            <v-tab-item>
                <v-row>
                    <v-col cols="12" md="6">
                        <RadioGroup v-model="enableSmsSending"
                                    class="mt-1"
                                    :options="defaultOptions"
                                    >
                        </RadioGroup>
                        <v-btn color="secondary"
                               :loading="loader"
                               >{{ $t('buttons.save') }}</v-btn>
                    </v-col>
                </v-row>

            </v-tab-item>
        </v-tabs>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import TextField from '@/components/widgets/forms/TextField';
import RadioGroup from '@/components/widgets/forms/RadioGroup';
import { ICONS } from '@/constants/icons';
import systemSettingsApi from '@/utils/api/settings';

export default {
    name: 'SmsGate',
    components: { RadioGroup, TextField },
    data: () => ({
        loader: false,
        phoneRegex: /^[0-9+\-\s]+/i,
        phone: '',
        updatedIds: [],
        enableSmsSending: 1,
        ICONS
    }),
    computed: {
        ...mapFields('systemSettings', [
            'synchSetupEntries'
        ]),
        filteredSynchSetupEntries () {
            return this.synchSetupEntries.filter(e => (e.attributes.name !== 'KRONOS_IMPORT') && (e.attributes.name !== 'SMSAPI_EXPORT'));
        },
        tabs () {
            return [
                { title: this.$t('labels.sms_sending_api'), icon: 'mdi-cellphone-basic' },
                { title: this.$t('labels.export_signals'), icon: 'mdi-export' }

            ];
        },
        defaultOptions () {
            return [
                {
                    text: this.$t('labels.turned_on'),
                    value: 1
                },
                {
                    text: this.$t('labels.turned_off'),
                    value: 0
                }
            ];
        },
        validPhone () {
            return this.phoneRegex.test(this.phone);
        }
    },
    methods: {
        updateList (id) {
            if (this.updatedIds.indexOf(id) === -1) {
                this.updatedIds.push(id);
            }
        },
        async save () {
            const dataToUpdate = this.synchSetupEntries.filter(el => this.updatedIds.includes(el.id));
            if (dataToUpdate.length > 0) {
                return await systemSettingsApi.updateSynchSetupEntries(dataToUpdate).finally(() => {
                    this.fetch();
                    this.updatedIds = [];
                });
            }
        },
        fetch () {
            this.loader = true;
            const params = {
                'filter[writable]': true
            };
            this.$store.dispatch('systemSettings/getSynchSetupEntries', params)
                .finally(() => {
                    this.loader = false;
                });
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.SYSTEM_SETTINGS)) {
                vm.fetch();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>
